
 .number {
     display: inline-block;
     width:100%;
     text-align: center;
     font-weight: bold;
     color: white;
     border-radius: 4px;
     background-color: lightseagreen;
 }

 .numberTotal{
     display: inline-block;
     text-align: center;
     font-weight: bold;
     width:100%;
     color: white;
     border-radius: 4px;
     cursor: pointer;
     background-color: red;
 }

 .timerAndLast {
     display: inline-block;
     width: 100%;
     text-align: center;
     border-radius: 4px;
     background-color: whitesmoke;
     margin-bottom: 10px;
     padding: 10px;
 }
