body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*!* 确保在 dark 模式下，Table 行的 hover 颜色是深色 *!*/
body[arco-theme='dark'] .arco-table tbody tr:hover {
  background-color: #303030 !important; /* 你可以换成更深的颜色 */
}

/* 禁用模态框的过渡动画 */
.arco-modal-wrapper {
  transition: none !important;
}

.arco-modal-mask {
  transition: none !important;
}

